.CreateInvoicePage {
  .ant-card-head {

    .ant-form-item {
      margin-bottom: 0;
    }

    input.ant-input[disabled] {
      color: black!important;
      background-color: white!important;
      font-size: 1.2em;
      cursor: default;
      margin-top: 0;
      padding-top: 0;
      opacity: 1;
    }

  }

  .inputPerUnit {
    & input {
      text-align: right;
    }
    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .react-datepicker-wrapper {

    .ant-btn-sm {
      height: 32px;
      width: 110px;
      padding: 4px 4px;
      font-size: 14px;
      border-radius: 2px;
    }

    .react-datepicker__close-icon::after {
      background-color: lightgrey;
    }
  }
}

.EditInvoicePage {
  .ant-form-item input.ant-input[disabled] {
    color: black!important;
    background-color: white!important;
    font-size: 1.4em;
    cursor: default;
    margin-top: 0;
    padding-top: 0;
    opacity: 1;
  }

}