.BillingTaxesPage{
  .filter__date{
    .ant-table-filter-column{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      .ant-table-filter-trigger-container{
        .ant-table-filter-trigger{
          line-height: 0;
          width: auto;
          color: #1890ff;
        }
        position: initial;
        align-self: initial;
        display: initial;
        svg{
          font-size: 1.4rem;
        }
      }
      .ant-table-filter-column-title{
        padding-left: 5px;
      }
    }
  }
}
