@import '../../../variables.scss';

.BillingProductsPage {
  .ant-table-row-level-1 {
    background: rgba($green-color, 0.1);

    &:hover > td {
      background: rgba($green-color, 0.07);
    }
    .ant-table-cell-with-append {
      &:before {
        content: 'L';
        left: 44px;
        top: 13px;
        position: absolute;
      }
    }
  }

  td.right {
    text-align: right;
  }

  th.right {
    text-align: right;
  }

  td.center {
    text-align: center;
  }

  th.center {
    text-align: center;
  }
}
