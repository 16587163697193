@import '../../../variables.scss';

.InvoiceCostAllocationsPage {
  .priceInput {
    & input {
      text-align: right;
    }
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
}

.invoiceAllocationCard {
  .ant-card-head {
    background-color: $grey-color;
  }
  .ant-card-head-title {
    font-weight: bold;
    padding: 8px!important;
  }

  .done {
    padding: 30px 50px;
    display: inline-block;
    text-align: center;
    background: white;
    color: $green-dark-color;
    width: 100%;
    font-size: 24px;
  }

}

