.select-company-width {
    border: 0;
    font-weight: bold;
    color: black;
}

.select-company-width > div.ant-select-selector {
    border: 0 !important;
    color: black;
}

.select-company-width > div.ant-select-selector {
    cursor: default !important;
    background-color: #efefef !important;
    border-radius: 10px !important;
    padding: 5px;
    color: black !important;
    border: 0 !important;
    font-size: 1.2em;
}
.select-company-width > .ant-select-disabled .ant-select-selector {
    cursor: default !important;
    background-color: #efefef !important;
    border-radius: 10px !important;
    padding: 5px;
    color: black !important;
    border: 0 !important;
    font-size: 1.2em;
}

.select-company-loginAs-width > div.ant-select-selector {
    border: 0 !important;
    color: black;
}

.select-company-loginAs-width > div.ant-select-selector {
    cursor: default !important;
    background-color: yellow !important;
    border-radius: 10px !important;
    padding: 5px;
    color: black !important;
    border: 0 !important;
    font-size: 1.2em;
}
.select-company-loginAs-width > .ant-select-disabled .ant-select-selector {
    cursor: default !important;
    background-color: yellow !important;
    border-radius: 10px !important;
    padding: 5px;
    color: black !important;
    border: 0 !important;
    font-size: 1.2em;
}