// Colors

$success-color: #28a745;
$warning-color: #eca52b;
$info-color: #1890ff;   // #17a2b8;
$dark-color: #343a40;
$light-dark-color: #6c757d;

$blue-color: #252951;
$blue-dark-color: darken($blue-color, 3%);
$green-color: rgb(45, 146, 89);
$green-light-color: lighten($green-color, 45%);
$green-dark-color: lighten($green-color, 15%);
$green-white-color: lighten($green-color, 56%);
$warning-light-color: lighten($warning-color, 45%);

$grey-color: #f5f5f5;
$light-grey-color: darken($grey-color, 25%);

$alert-color: rgb(215, 72, 72); // #ff2222;
$alert-lighter-color: rgb(240, 100, 100);
$alert-light-color: rgb(255, 200, 200);

$dashed-grey: 1px dashed #d9d9d9;
$disabled-grey: #c4c4c4;

$ant-blue-color: #1890ff;
$blue-light-color: lighten($ant-blue-color, 40%);
$blue-font-color: lighten($ant-blue-color, 10%);

$menu-active: #e6f7ff;
$menu-active-border: #d3edfc;
// Themes

//light
$light-theme-color: #fff;

//dark
$dark-theme-border-color: rgba(255, 255, 255, 0.2);
$dark-theme-font-color: #fff;
