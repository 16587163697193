@import '../../../variables.scss';

.EditInvoicePage {
  max-width: 1200px;
  overflow-x: hidden!important;

  .ant-card-body {
    overflow-x: hidden!important;
  }

  .invoice_items{
    .ant-form-item{
      margin-bottom: .6rem;

      .inputName_textArea, .ant-picker.ant-picker-large{
        padding: 3px 4px;
      }

      &.inputNoVat,
      &.inputUnitCount,
      &.inputUnit,
      &.inputPerUnit,
      &.inputNoVat,
      &.inputVat,
      &.inputTotal{
        input{
          height: 33px;
        }
      }


      &.inputTax{
        .ant-select-selector{
          height: 35px;
          .ant-select-selection-item{
            line-height: 35px;
          }
        }
      }

      .upDownWrapper{
        height: 35px;
        .ant-btn-icon-only.ant-btn-sm{
          height: 17.5px;
          &.upDownWrapper_button-up{
            line-height: 0;
          }
        }
      }

      .remove_btn{
        height: 35px;
      }

      .addRowButton{
        margin-top: .8rem;
      }
    }
  }

  .ant-row {
    background-color: $light-theme-color;
    padding: 2px 8px;
  }
  .ant-col {
    background-color: transparent!important;
    padding: 4px 4px;
    border: 0 solid #dcdcdc;

    label {
      padding: 5px;
      display: block;
    }
    .value {
      padding: 5px 0 5px 0;
      font-weight: bold;
      display: block;
    }
  }

  .docFrame {
    max-height: 160px;
    overflow-y: auto;
  }

  div.invoiceHeader {
    height: 32px;
    position: relative;
    padding: 6px!important;
  }

  div.invoiceHeader::before {
    display: inline-block;
    height: 100%;
    content: '';
  }

  span.invoiceHeader {
    font-size: 30px;
    vertical-align: baseline;
  }

  .invoiceNumber {
    font-size: 1.4em;
    font-weight: bold;
    font-family: monospace;
  }

  .companyName {
    margin-top: 2px;
    margin-bottom: 4px;
    font-size: 1.2em;
    font-weight: bold;
  }

  .period {
    font-size: 1.2em;
    font-weight: bold;
  }

  th.price {
    text-align: center;
  }
  .price {
    text-align: right;
    padding-right: 0;
  }
  .priceFix {
    text-align: right;
    padding-right: 4px;
    border-right: 2px solid lightcoral!important;
  }
  .priceUnit {
    text-align: right;
    padding-right: 4px;
    border-right: 2px solid cornflowerblue!important;
  }
  .priceRange {
    text-align: right;
    padding-right: 4px;
    border-right: 2px solid lightgreen!important;
  }

  .react-datepicker-wrapper {

    .ant-btn-sm {
      height: 32px;
      width: 110px;
      padding: 4px 4px;
      font-size: 14px;
      border-radius: 2px;
    }

    .react-datepicker__close-icon::after {
      background-color: lightgrey;
    }
  }

  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }

}



.inputPerUnit {
  & input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.credit-edit {
  float: right;
  margin-bottom: 20px;
}

.appLink {
  color: dodgerblue;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.invoice-finalized {
  border-top: 2px solid $green-dark-color;
}

.invoice-prepared {
  border-top: 2px solid $alert-lighter-color;
}
