@import '../../../variables.scss';

.billsPage {

}


.UpdateBillPage {


  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    border: 1px dotted $blue-light-color;
  }

  .editable-row:hover .editable-cell-value-wrap {
    //padding: 4px 11px;
    //border: 1px solid $disabled-grey;
    background-color: white;
    border-radius: 2px;

  }
  input#total_without_vat {
    text-align: right;

  }

  .ant-input-number-handler-wrap {
    display: none;
  }


  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .sumTD {
    border-top: 2px solid black;
  }
}
