
.logViewerPage {
  .space {
    padding: 5px;
    border: #c5ffc5 solid 0px;
  }
  div.ant-popover-content {
    max-width: 800px;
    overflow-x: auto;
    overflow-y: hidden;
    border: #d9d9d9 1px solid;
  }
  .react-datepicker-wrapper2222 {
    input[type=text] {
      border: #d9d9d9 1px solid;
      padding: 4px 4px;
    }
  }
}


.SubLogTable {
  font-family: monospace;
  background: rgba(45, 146, 89, 0.06);
  border-spacing: 4px;

  table.ant-table  {
    width: 100%;
  }

  td.ant-table-cell {
    padding: 2px!important;
    background: rgba(45, 146, 89, 0.1);
  }
  th.ant-table-cell {
    padding: 2px!important;
    background: rgba(45, 146, 89, 0.2);
  }
  th.ant-table-cell-fix-right {
    right: auto!important;
  }

  .msg-col {
    text-align: left;
    max-width: content-box;
  }

  .msg-box {
    max-width: fit-content;
    max-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.LogTable {
  font-family: monospace;

  td.ant-table-cell {
    padding: 2px!important;
  }

  td.ant-table-cell-row-hover {
    background: rgba(45, 146, 89, 0.06);
  }

  .msg-col {
    text-align: left;
    max-width: content-box;
  }

  .msg-box {
    max-width: fit-content;
    max-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

