.progress {

    display: flex;
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;

    padding: 5px;

    &-line {

        width: 100%;
        background-color: #e9ecef;
        display: flex;
        font-size: 0.75rem;
        text-align: center;

    }

    &-bar {

        display: flex;

        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #007bff;
        transition: width 0.6s ease;
    }
}



.borader {
    &-bar {
        align-items: center;
        justify-content: center;
        display: flex;
        border: 0;
        border-top: 2px solid #e9ecef;
        border-bottom: 2px solid #e9ecef;
        border-left: 1px solid #e9ecef;
    }
}

.type {
    text-align: center;
    width: 100px;
}