
.DateInput {
  .date-input-box {
    float: left;
    border: solid 1px green;
  }

  .react-datepicker-wrapper {
    width: 120px;
    float: left!important;

    input {
      width: 120px;
      float: left!important;
      border-radius: 3px!important;
      border: #d9d9d9 1px solid;
      padding: 4px 4px;
    }
  }

  .date-input-side {
    margin-left: 5px;
    width: 30px;
    float: left!important;
  }
}
