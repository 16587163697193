@import '../../../variables.scss';

div.componentsTabs {
  .ant-tabs-tab-disabled {
    border: 0 !important;
    background-color: white !important;
    color: #222222;
  }
}

div.componentOption {
    padding: 8px;
    border: $menu-active-border solid 1px;
    border-radius: 4px;
    background-color: $menu-active;

    .serial {
      background-color: $menu-active-border;
      border-radius: 4px;
      padding: 2px 10px 2px 10px;
      margin: 2px;
    }

    .location {
      background-color: #ececec;
      border-radius: 4px;
      float: right;
      padding: 2px 10px 2px 10px;
      margin: 2px;
      font-size: 0.8em;
    }
}

div.selectComponent80 > .ant-select-selector {
  height: 80px !important;
}

.inventoryItem > .ant-select-selector {
  height: 80px !important;
}

div.ant-tabs-tab-active {
  background-color: $menu-active-border !important;
}

.PhysicalServersTable {

  th {
    padding: 2px 5px 2px 5px!important;
  }
  td {
    padding: 4px 4px 4px 4px!important;
  }

  .ant-table-column-sorters {
    display: inline;
  }
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    background-color: #ccc;
    cursor: col-resize;
  }
}