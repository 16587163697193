@import '../../variables.scss';

.HistoryLog {
  .space {
    padding: 5px;
    border: #c5ffc5 solid 0px;
  }
}

.histLogCard {
  overflow-x: hidden;
  overflow-y: hidden;

  .ant-card-head {
    background-color: $grey-color;
  }
  .ant-card-head-title {
    font-weight: bold;
    padding: 8px!important;
  }
}

.historyLogModal {

  .ant-modal-body {
    padding: 2px;
    overflow-y: hidden!important;
  }
  .ant-modal-title {
    font-weight: bold;
  }
  .ant-modal-header {
    background-color: $grey-color;
  }
}

.HistoryLogPanel {
  font-family: monospace;

  .ant-table-content {
    min-height: 250px!important;
  }
  .ant-table-body {
    min-height: 250px!important;
  }

  .ant-table-expand-icon-col {
    width: 20px;
  }

  td.ant-table-cell {
    padding: 2px!important;
  }

  td.ant-table-cell-row-hover {
    background: rgba(45, 146, 89, 0.06);
  }

  .msg-col {
    text-align: left;
  }

  .msg-box {
    max-width: content-box;
    max-height: 24px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.HistoryLogTable {
  /* Modal */
  font-family: monospace;

  .ant-table-content {
    min-height: 450px!important;
  }
  .ant-table-body {
    min-height: 450px!important;
  }

  .ant-table-expand-icon-col {
    width: 20px;
  }

  td.ant-table-cell {
    padding: 2px!important;
  }

  td.ant-table-cell-row-hover {
    background: rgba(45, 146, 89, 0.06);
  }

  .msg-col {
    text-align: left;
  }

  .msg-box {
    max-width: content-box;
    max-height: 24px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.HistorySubLogTable {
  font-family: monospace;
  background: rgba(45, 146, 89, 0.06);
  border-spacing: 4px;

  table.ant-table  {
    width: 100%;
  }

  .ant-table-content {
    min-height: 0px!important;
  }
  .ant-table-body {
    min-height: 0px!important;
  }

  td.ant-table-cell {
    padding: 2px!important;
    background: rgba(45, 146, 89, 0.1);
  }
  th.ant-table-cell {
    padding: 2px!important;
    background: rgba(45, 146, 89, 0.2);
  }
  th.ant-table-cell-fix-right {
    right: auto!important;
  }

  .tdLevel {
    width: 60px!important;
    text-align: right;
  }

  .msg-col2 {
    text-align: left;
    max-width: content-box;
    width: 96%;
  }

  .msg-box2 {
    max-width: fit-content;
    max-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}