.PasswordRequestPage {
  max-width: 800px;

  &_action-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
    }
  }

  .login-form {
    padding: 20px;
  }
}