.searchBox {
    border: red solid 0px;
    font-weight: bold;
    color: black;
    padding-top: 0 !important;
    margin: 5px 80px 2px 5px !important;
    margin-top: -10px !important;
    width: 170px !important;
}

.searchBox input {
    padding: 3px;
    height: auto;
    width: 120px !important;
}
.searchBox button {
    height: 32px !important;
    border: #dcdcdc solid 1px !important;
    display: none !important;
}

.searchBoxDropDown1 {
    min-height: 500px;
    max-height: 1000px;
    height: auto;
    overflow-y: visible;
}

li.ant-menu-item {
    cursor: default !important;
}


.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.AppBar > .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 0 !important;
}
