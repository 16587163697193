@import '../../variables.scss';

.history-page {
  font-family: monospace!important;

  .when-width {
    width: 140px;
    white-space:nowrap
  }
  .ip-width {
    width: 130px;
    white-space:nowrap
  }
  td {
    padding: 5px 8px!important;
  }
}
.history-form-item {
  margin-bottom: 0!important;
  margin-left: 20px;
}
.history-form-start {
  margin-bottom: 0!important;
}
.history-form-item .ant-form-item-control-input {
  min-height: 22px!important;
  font-size: 14px!important;
}

.ant-modal-wrap{
  max-height: calc(100vh - 40px);
  overflow: hidden;
  .modal__wrapper{
    top: 20px;
    bottom: 20px;
    .ant-modal-body{
      max-height: calc(100vh - 130px);
    }
  }
}



td.histTD {
  font-family: monospace;
  font-weight: normal;
  padding: 2px!important;
}

.historyModal {
  .ant-modal-body {
    padding: 2px;
    overflow-y: scroll;
    max-height: 1000px;
  }
  .ant-modal-title {
    font-weight: bold;
  }
  .ant-modal-header {
    background-color: $grey-color;
  }
}

.historyCard {
  .ant-card-head {
    background-color: $grey-color;
  }
  .ant-card-head-title {
    font-weight: bold;
    padding: 8px!important;
  }

  .ant-table-content {
    min-height: 150px!important;
  }
  .ant-table-body {
    min-height: 150px!important;
  }
}
