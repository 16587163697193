@import '../../variables.scss';

.companyDetail {
  .ant-form-item {
    margin-left: 20px;
    margin-bottom: 2px;
  }
  label {
    font-weight: bold;
  }
}


.CompaniesTable {
  .isCustomer {
    background: $green-white-color;
  }
  .label-bold {
    font-weight: bold;
    margin-bottom: 0;
  }
  span {
    font-weight: normal;
  }
}

.CompanyForm {
  .isCustomer {
    background: $green-white-color;
    padding: 4px;
  }
}

.expandCompaniesTable {
  .ant-table-small {
    margin-left: 0 !important;
  }
}

.company-id-form {
  height: 24px!important;
  padding: 0 8px!important;
}