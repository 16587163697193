
.react-datepicker-wrapper {
  input[type=text] {
    border-radius: 3px!important;
    border: #d9d9d9 1px solid;
    padding: 4px 4px;
  }
}

table.date-range-box {
  display: block;
  max-width: 320px;
  height: 32px!important;
  // background-color: #f8f8f8;
  float: left;
  padding: 0;
  border: 0 solid #ededed;

  tr {
    padding: 0;
  }
  td {
    padding: 0;
  }
}

.date-range-start {
  width: 100%;
  max-width: 100px;
  height: 28px!important;
  float: left;
  text-align: center;
  margin: 1px 5%;
  padding: 2px;
}

.date-range-end {
  width: 100%;
  max-width: 100px;
  height: 28px!important;
  float: left;
  text-align: center;
  margin: 1px 5%;
  padding: 2px;
}

.period-name {
  padding: 5px;
  margin: 2px;
}

.period-name {
  padding: 5px;
  margin: 2px;
  font-size: 1em;

  &:hover {
    background-color: #108ee9;
    color: white;
    cursor: pointer;
  }
}
