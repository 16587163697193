.CustomerServiceForm {
  .ant-input-group-addon {
    padding: 0 6px;
    font-weight: bold;
    width: 43px;
  }

  .ant-form-item-label {
    padding: 0!important;
  }
}

.CustomerServiceOneTimeForm {
  .ant-input-group-addon {
    padding: 0 6px;
    font-weight: bold;
    width: 43px;
  }

  .ant-form-item-label {
    padding: 0!important;
  }
}

.CustomerServiceFormUpgrade {
  .ant-input-group-addon {
    padding: 0 6px;
    font-weight: bold;
    width: 43px;
  }

  .ant-form-item-label {
    padding: 0!important;
  }
}