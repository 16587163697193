
.DashboardPage {

    height: 100%;
    min-width: 100%;

  .role-box {
    .ant-form-item-control-input-content {
      height: 200px;
      overflow-y: scroll;
    }
  }

  .ant-card-body {
    padding: 8px;
  }

  .ant-form-item {
    margin-bottom: 4px;
    &:nth-of-type(1) {
      margin-bottom: 0;
    }
    &:not(:nth-last-of-type(1)){
      align-items: center;
    }
    &:nth-last-of-type(1){
      margin-top: 4px;
    }
  }

  .group-list {
    .ant-form-item-control{
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      padding: 1px;
      margin-top: 1px;
      height: 200px;
      width: 240px;
      overflow: auto;
    }
  }

  .ant-form-item-label{
    min-width: 95px;
    margin-right: .5rem;
    label{
      font-size: 1rem;
      font-weight: 500;
      color: #bfbfbf;
    }
  }

  .ant-form-item-control {
    flex: 1 1;
    min-width: 120px;
    max-width: 160px;
  }

  th.last_seen_col {
    display: none;
  }


  .last-table {
    min-height: 300px;
    max-height: 300px;
    height: 90%;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .tdOver {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-body {
      height: 300px;
    }
  }

}
