@import '../../variables.scss';

.AppBar {
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  z-index: 2;

  &.ant-layout-header {
    height: 66px;
    padding: 0 25px;
  }

  &.ant-layout-header,
  .ant-menu-dark {
    background-color: $blue-color !important;
  }
  &.light {
    background: $light-theme-color !important;
  }
  .ant-menu-item-disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .todayDate {
    font-size: 1.2em;
    color: black!important;
    font-weight: bold;
    cursor: default;
  }
  .todayTime {
    font-size: 1.2em;
    color: gray!important;
    font-weight: bold;
    cursor: default;
  }
  .todayTimeRed {
    font-size: 1.2em;
    color: red!important;
    font-weight: bold;
    cursor: default;
  }
  .companyName {
    font-size: 1.2em;
    color: black!important;
    background-color: #efefef !important;
    border-radius: 10px !important;
    padding: 5px;
    font-weight: bold;
    cursor: default;
  }
  .companyName-loginAs {
    font-size: 1.2em;
    color: black!important;
    background-color: yellow !important;
    border-radius: 10px !important;
    padding: 5px;
    font-weight: bold;
    cursor: default;
  }

}

.ant-dropdown-menu-item {
  height: 40px !important;
  line-height: 40px !important;
}
