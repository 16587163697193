.InvoiceAttachment {
  .ant-upload.ant-upload-select-picture-card {
    float: unset;
  }

  .ant-upload-disabled{
    button{
      opacity: .3;
      cursor: not-allowed;
    }
  }

  .ant-upload-list-item-name {
    color: dodgerblue;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
