
.ServersPage {

  .tag {
    padding: 1px;
    margin: 1px;
    line-height: unset;
  }

  .microTag {
    font-size: 0.6em;
    padding: 1px;
    margin: 1px;
    line-height: unset;
  }

  .serverExpanded {
    .ant-form-item {
      margin-bottom: 1px !important;
    }
  }

}