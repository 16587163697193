@import '../../../variables.scss';

.inventoryModalCreate {
    width: 500px!important;
}
.inventoryModalEdit {
    width: 800px!important;
}


.inventoryTypeTable {
    tr.unused {
        background-color: $green-white-color;
    }
}

.inventoryTable {
    tr.disposed {
        background-color: #ededed !important;
    }

    tr.disposed > td {
        background-color: #ededed !important;
    }

    tr.sold {
        background-color: blanchedalmond !important;
    }
    tr.sold > td{
        background-color: blanchedalmond !important;
    }

    th.right {
        text-align: center;
    }

    tr.sold > td.ant-table-cell-fix-right-first {
        background-color: white !important;
    }
    tr.disposed > td.ant-table-cell-fix-right-first {
        background-color: white !important;
    }
}

.inventoryEditForm {
    input.price {
        text-align: center;
        padding-right: 10px;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    #date_edit {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        max-width: 100%;
        float: left;
        text-align: left;
        padding: 4px 11px 4px 11px;
    }
}

.createInventory {

    input {
        max-width: 160px!important;
    }

    #date_create {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        max-width: 160px;
        float: left;
        text-align: left;
        padding: 4px 11px 4px 11px;
    }


    input.price {
        text-align: center;
        padding-right: 10px;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    div.ant-form-item {
        margin-bottom: 16px;
    }
}

.formItem {
    margin-bottom: 8px!important;
}

.site-tag-plus {
    background: #fff;
    border-style: dashed;
}
.edit-tag {
    user-select: none;
}
.tag-input {
    width: 78px;
    margin-right: 8px;
    vertical-align: top;
}
