
.UserRightsPage {

  .tag-res-group {
    font-size: 1em;
    cursor: pointer;
  }

  .tag-user {
    font-size: 1em;
    cursor: pointer;
  }

  .tag-role {
    font-size: 1em;
    cursor: pointer;
  }

  .tag-action {
    padding: 0 4px;
  }

  .tr {
    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    padding: 0 !important;
    margin: 0 !important;
  }

  .tr-selected {
    .td-blue {
      background-color: #e6fffb;
      color: white;
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    padding: 0 !important;
    margin: 0 !important;
  }

  .users-table {
    min-height: 600px;
    max-height: 700px;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .tdOver {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-body {
      height: 600px;
    }
  }

  .role-table {
    min-height: 300px;
    max-height: 350px;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .tdOver {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-body {
      height: 300px;
    }
  }

  .res-group-table {
    min-height: 300px;
    max-height: 350px;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .tdOver {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-body {
      height: 300px;
    }
  }

  .group-table {
    min-height: 300px;
    max-height: 350px;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .ant-table-body {
      height: 300px;
    }
  }

  .group-all-table {
    min-height: 300px;
    max-height: 350px;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .tdOver {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-body {
      height: 300px;
    }
  }
}