.GraphLegendItem {
    display: flex;
    align-items: center;

}

.legend-color {
    max-width: 16px;
    max-height: 16px;
    display: inline-block;
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 5px;

}