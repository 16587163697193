@import '../../../variables.scss';

.CustomersTable {
  .terminated {
    background: $grey-color;
  }
}


.contactForm {
  background-color: white;

  .label {
    margin: 4px;
    padding: 4px;
    float: right;
    text-align: right;
    font-weight: bold;
  }

  .field {
    margin: 0px;
    padding: 4px;
    text-align: left;
    font-weight: normal;
  }

}