

.HypervisorsTable {
  .high > .cpu {
    background-color: lightpink;
  }
  .middle > .cpu {
    background-color: #d3edfc;
  }
  .low > .cpu {
    background-color: #c5ffc5;
  }


}