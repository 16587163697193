@import '../../variables.scss';

.UsersTable {
  .hide {
    display: none;
  }
  tr.deleted {
    background-color: $disabled-grey;
  }
  .zcom {
    background: $green-white-color;
  }
  .zcom_sys {
    background: $green-color;
    a {
      color: white;
    }
    a:hover {
      color: $ant-blue-color;
    }
  }
  .label-bold {
    font-weight: bold;
    margin-bottom: 0;
  }
  span {
    font-weight: normal;
  }
  .mr-2 {
    margin-right: 2rem;
  }
}
.UsersTable th.show input {
  margin-top: 10px;

}
.reactquillHight .ql-container  {
  height: 150px!important;
}

.EditUserPage {
  max-width: 1200px;

  .ant-card-body {
    overflow-x: hidden!important;
  }

  .ant-row {
    background-color: $light-theme-color;
    padding: 2px 8px;
    margin-left: 0!important;
    margin-right: 0!important;
  }
  .ant-col {
    padding: 4px 4px;
    border: 0 solid #dcdcdc;

    label {
      padding: 5px;
      display: block;
    }

    .value {
      padding: 5px 0 5px 0;
      font-weight: bold;
      display: block;
    }
  }

  .fblock {
    background-color: $light-theme-color;
    border-radius: 5px;
    border: #f0f0f0 1px solid;
    margin-top: 16px;
    margin-bottom: 16px;

    .ant-card-head {
      min-height: 32px;
      font-size: 1.2em;
      font-weight: bold;
      padding: 0 24px 0 12px;
      background-color: #f0f0f0;  // #d3edfc

      .ant-card-head-title {
        padding: 6px 0;
      }
      .ant-card-extra {
        padding: 6px 0;
      }
    }
    .ant-card-body {
      padding: 8px;
    }

    .ant-radio-button-wrapper {
      padding: 2px 16px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .labelCol {
      text-align: right;
      padding: 8px;
      //border: 1px solid red;
    }

    .valueCol {
      padding: 8px;
      //border: 1px solid red;
    }

    .label {
      font-weight: normal;
      //background-color: yellow;
      display: inline;
      padding: 0;
    }

    .value {
      font-weight: bold;
      //background-color: yellow;
      display: inline;
      padding: 0;
    }
  }

  .settingItem {
    margin-top: 35px;
    padding: 0;
    border: 1px solid #fafafa;

    .labelCol {
      background-color: #fafafa;
      padding: 12px;
    }
    .valueCol {
      background-color: #fafafa;
    }
    .btnCol {
      background-color: #fafafa;
      text-align: center;
      padding: 12px;
    }
    .scopeCol {
      text-align: center;
      padding: 12px;
    }
    .label {
      font-weight: bold;
      //background-color: yellow;
      display: inline;
      padding: 0;
    }
  }

  .Log2faTable {
    border: 1px solid #dedede!important;
    height: 150px;

    .ant-table-tbody {
      height: 50px!important;

    }
    .ant-table-container {
      height: 50px!important;

    }
    .ant-table-cell {
      padding: 1px!important;
    }

    th.ant-table-cell {
      padding: 2px!important;
    }

    .ant-empty-normal {
      margin: 8px 0;
    }

  }

  .AccountMenu {
    font-size: 1.1em;
    .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0;
      border-top: 1px solid #efefef;
      border-right: 1px solid #efefef;
      border-left: 1px solid #efefef;
      border-bottom: 0;
      background-color: white;
    }

    .ant-menu-item {
      margin-top: 0;
      bottom: 0;
      border: 1px solid #efefef;
      background-color: white;
    }
    .ant-menu-item-selected {
      background-color: #e6f7ff!important;
    }
    .ant-menu-item:hover {
      margin-top: 0;
      bottom: 0;
      background-color: #e6f7ff;
    }

  }

  .histLogCard {
    height: 920px;
    .HistoryLogPanel {

      .ant-table-body {
        max-height: 800px!important;
        height: 800px!important;
      }
    }
  }
}
