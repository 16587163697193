@import-normalize;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&subset=latin-ext');

@import './variables';

@import '~antd/dist/antd.css';
@import '~antd-button-color/dist/css/style.css';

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 5px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 1px;
}


.ant-menu-submenu-popup {
  position: absolute !important;
  top: 60px !important;
  right: unset !important;
  left: 930px !important;
  width: 200px;
  border: 0;
}


.ant-card-body {
  padding: 0;
}

.ant-btn,
.ant-btn-lg {
  margin: 0 4px;
}

.ant-input-number-input {
  padding: 0 4px;
}

.ant-input-number-input {
  color: #222222;
}

body {
  font-family: 'Open Sans', sans-serif;
}

.App {
  display: flex;
  min-height: 100vh;

  .noWrap {
    white-space: nowrap;
  }

  .App_inner {
    display: flex;
    flex: 1;
    flex-direction: column;

    .centered {
      text-align: center;
    }
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .ant-layout-sider.ant-layout-sider-dark,
  .ant-menu-dark {
    background-color: $blue-color !important;
  }

  .text-white {
    color: $light-theme-color;
  }

  .inline {
    display: flex;
  }

  .inline>* {
    display: flex;
  }

  .alignCenter {
    align-items: center;
  }

  .alignStart {
    align-items: flex-start;
  }

  .alignEnd {
    align-items: flex-end;
  }

  .justifyCenter {
    justify-content: center;
  }

  .justifyAround {
    justify-content: space-around;
  }

  .justifyBetween {
    justify-content: space-between;
  }

  .justifyEnd {
    justify-content: flex-end;
  }

  .justifyStart {
    justify-content: flex-start;
  }

  .highlight {
    animation: highlight 800ms ease-out;
  }

  @keyframes highlight {
    0% {
      background-color: rgba(255, 255, 0, 0.3);
    }

    100% {
      background-color: transparent;
    }
  }

  .ant-table-tbody>.ant-table-row:hover>td {
    background: rgba($green-color, 0.06);
  }

  .historyGrid {
    .ant-row {}

    .ant-col {
      padding: 0;
    }
  }
}

.anticon.anticon-close.ant-modal-close-icon {
  background: #fff;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.ant-modal-body {
  max-height: 90vh;
  overflow-y: scroll;
}

.zcom-error-message {
  .ant-message-notice-content {
    background: $alert-light-color;
    border: 1px solid $alert-color;
  }
}

.totalPrice {
  text-align: right !important;
}

.inputPriceField {
  & input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.zcom-green {
  background: $green-color;
}

.bg-primary {
  background-color: $green-color; //#4190F7;
}

.SideNav .sideMenu {
  overflow-y: visible !important;
}

.UsersPage .ant-card-body {
  overflow-x: overlay;
}

.CompanyPage .ant-card-body {
  overflow-x: overlay;
}

.BillingProductsPage .ant-card-body {
  overflow-x: overlay;
}

.BillingTaxesPage .ant-card-body {
  overflow-x: overlay;
}

.BillingPricelistPage .ant-card-body {
  overflow-x: overlay;
}

.InvoicePage .ant-card-body {
  overflow-x: overlay;
}

.BillsPage .ant-card-body {
  overflow-x: overlay;
}

.ant-card-body {
  overflow-x: overlay;
}

.text-danger {
  color: $alert-color;
}

.text-primary {
  color: #1890ff;
}

.text-dark {
  color: black;
}

.text-center {
  text-align: center;
}

.localhost section section {
  background: lightpink;
}

.localhost section section section {
  background: lightpink;
}

.stage section section {
  background: lightblue;
}

.stage section section section {
  background: lightblue;
}

.devel section section {
  background: lightyellow;
}

.devel section section section {
  background: lightyellow;
}

.ant-card-body {
  overflow-x: hidden;
}

.text-success {
  color: $success-color;
  font-weight: bold;
}
.text-warning {
  color: $warning-color;
  font-weight: bold;
}
.text-alert {
  color: $alert-color;
  font-weight: bold;
}
.success {
  background-color: $success-color;
  color: white;
}

.success-border {
  border: 1px solid $success-color!important;
}

.warning {
  background-color: $warning-color;
}

.alert {
  background-color: $alert-color;
  color: white;
}

.info {
  background-color: $info-color;
}

.info-border {
  border: 1px solid $info-color!important;
}

.disabled-border {
  border: 1px solid $disabled-grey!important;
}

.dark {
  background-color: $dark-color;
  color: white;
}

.zis-alert {
  margin: 2px;
  padding: 2px;
  text-align: center;
}

.light-dark {
  background-color: $light-dark-color;
  color: #dedede;

  &:hover {
    background-color: $dark-color;
    color: white;
    cursor: pointer;
  }
}

.light-dark[disabled] {
  background-color: $disabled-grey;
  color: #dedede;
  &:hover {
    background-color: $disabled-grey;
    color: #dedede;
  }
}

.text-disabled {
  color: $disabled-grey !important;
  cursor: not-allowed;
}

.text-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.link {
  color: dodgerblue !important;
}

.linkUnder {
  color: dodgerblue !important;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.small {
  font-size: small;
  color: #343a40;
}

.bg-primary {
  background-color: #4190F7;
}

.bg-success {
  background-color: #70C040;
  color: white;
}

.bg-warning {
  background-color: #EFAF41;
  color: black;
}

.red {
  background-color: $alert-color;
}

.bg-danger {
  background-color: $alert-color;
  color: white;

  &:hover {
    background-color: $alert-lighter-color;
    color: white;
    cursor: pointer;
  }
}

.bold {
  font-weight: bold;
}

.middle {
  vertical-align: middle;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

td.center {
  text-align: center;
}

td.right {
  text-align: right;
}

td.left {
  text-align: left;
}

th.center {
  text-align: center;
}

th.right {
  text-align: right;
}

th.left {
  text-align: left;
}

.pad8 {
  padding: 8px;
}

.pad6 {
  padding: 6px;
}

.pad4 {
  padding: 4px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt8 {
  margin-top: 8px;
}

.ml8 {
  margin-left: 8px;
}

.mr8 {
  margin-right: 8px;
}

.margin-8 {
  margin: 8px;
}

.whiteRow {
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: white;

  div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

input.numField {
  text-align: right;
}

.title2 {
  font-size: 1.2em;
  font-weight: bold;
}
.title3 {
  font-size: 1.2em;
  font-weight: bold;
}

.textGray {
  font-size: 0.9em;
  font-weight: normal;
  color: #444444;
}

.actionButton {
  border: 0 !important;
  margin: 0 2px !important;
  background-color: transparent !important;
}

.ant-table-cell-row-hover {
  border-top: 1px solid deepskyblue!important;
  border-bottom: 1px solid deepskyblue!important;
  background-color: #dfeff8 !important;
  cursor: default;
}

.fullwidth-loading {
  width: 100vw;
  height: 100vh;
  background: rgba(221, 221, 221, .18);
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .LoadingIndicator {
    margin-left: 219px;
  }
}

.loading-app {
  .progress-container {
    width: 300px;
    margin: auto;
  }

  .progress-bar {
    height: 4px;
    background-color: rgba(5, 114, 206, 0.2);
    width: 100%;
    overflow: hidden;
  }

  .progress-text {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 0.8em;
  }

  .progress-header {
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 1.4em;
  }

  .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: rgb(5, 114, 206);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
  }

  @keyframes indeterminateAnimation {
    0% {
      transform:  translateX(0) scaleX(0);
    }
    40% {
      transform:  translateX(0) scaleX(0.4);
    }
    100% {
      transform:  translateX(100%) scaleX(0.5);
    }
  }
}

.page-loading {
  width: 100%;
  height: 100%;
  background: rgba(221, 221, 221, .18);
  z-index: 999;
  align-items: center;
  justify-content: center;

  .LoadingIndicator {
    margin-left: 0;
  }
}

.loading {
  margin: 20px 0;
  padding: 30px 50px;
  text-align: center;
  background: transparent;
  border-radius: 4px;
}

.fullwidth-error {
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-loading {
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .LoadingIndicator {
    margin-top: 50px;

    img {
      max-width: 40px;
      max-height: 40px;
      position: fixed;
      z-index: 99;
    }
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.confirm-warning {
  border: 2px solid $warning-color;
  padding: 0;
  .ant-modal-body {
    overflow-y: hidden;
  }
  .ant-modal-confirm-title {
    font-weight: normal;
  }
  .ant-modal-confirm-content {
    font-weight: bold;
    background-color: #ededed;
    margin: 20px;
  }
}

.confirm-alert {
  border: 2px solid $alert-color;
  padding: 0;
  .ant-modal-body {
    overflow-y: hidden;
  }
  .ant-modal-confirm-title {
    font-weight: normal;
  }
  .ant-modal-confirm-content {
    font-weight: bold;
    background-color: #ffffff;
    margin: 20px;
    padding: 4px;
  }
  .ant-modal-confirm-btns {
    .ant-btn-primary {
      background-color: $alert-color;
      border-color: $alert-color;
      color: white;
    }
  }
}

.modalComment {
  width: 500px!important;
}

.reloadBtn {
  border-color: $green-dark-color;
  border-radius: 4px;
  color: $green-color;
  font-size: 0.7em;
  padding: 0 4px;
  height: 20px;
}

.reloadBtn:hover {
  background-color: $green-color;
  border-color: $green-dark-color;
  color: white;
}
.reloadBtn:focus {
  background-color: $green-color;
  border-color: $green-dark-color;
  color: white;
}

.btnSave {
  background-color: $green-color;
  text-decoration: underline!important;
  color: white;

  &:hover {
    background-color: $green-dark-color;
    color: white;
    border: 1px solid $green-white-color;
    cursor: pointer;
  }
}

.btnProfile {
  border-radius: 10px;
  border: solid 1px $blue-light-color;
  &:hover {
    border: solid 1px $info-color;
    cursor: pointer;
  }
}

.alertBox .ant-popover-inner {
  border: 2px solid $alert-color;
  background-color: $alert-light-color;
  .ant-popover-title {
    font-size: 1.1em;
    font-weight: bold;
    background-color: $alert-color;
    color: white;
  }
  .ant-popover-inner-content {
    background-color: $alert-light-color;
  }
}

.btnDelete {
  background-color: white;
  text-decoration: underline!important;
  color: $alert-lighter-color;

  &:hover {
    background-color: #fcfcfc;
    color: $alert-color;
    border: 1px solid $alert-lighter-color;
    cursor: pointer;
  }
}

.btnLink {
  background-color: white;
  text-decoration: underline!important;
  color: $info-color;

  &:hover {
    background-color: #fcfcfc;
    color: $info-color;
    border: 1px solid $info-color;
    cursor: pointer;
  }
}

.btnBack {
  font-size: 0.8em;
  height: 24px;
  padding: 1px 6px;
  text-decoration: underline;
}