@import '../../../variables.scss';

.ProductPricePage {
  th.priceCell {
    text-align: inherit;
  }

  .ant-row {
    background-color: $light-theme-color;
    padding: 2px 8px;
  }
  .ant-col {
    background-color: transparent !important;
    padding: 4px 4px;
    border: 0 solid #dcdcdc;

  }

    .priceCell {
    // display: flex;
    // justify-content: space-between;
    text-align: right;

    .Price {
      text-align: left;

      .ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        overflow-y: hidden;
      }

      &_Tag {
        &_iconWrapper {
          position: relative;
          width: 23px;
          height: 9px;
          display: inline-block;

          &_icon {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }

        &_unit {
        }
      }
      &_priceWrapper {
        white-space: nowrap;
        float: right;

        &_price {
        }
        &_currency {
        }
      }
    }
  }
}

.ProductPriceForm {
  &_buttongroup {
    display: flex;
    .ant-radio-button-wrapper {
      flex: 1;
      text-align: center;
    }
  }
  .RangeWrapper {
    border: $dashed-grey;
    margin-bottom: 5px;
    padding: 20px 20px 0 0;

    .btnPrefix {
      margin-left: -9px;
    }
  }
  &_space {
    &_button-add {
      display: flex;
      justify-content: center;
    }
  }
}
