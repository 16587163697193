@import '../../../variables';

.DnsServicePage {
  max-width: 100%;

  .dnsServicesTable {
    td {
      padding: 5px!important;
    }
    th {
      padding: 5px!important;
    }
    tbody {
      padding: 0!important;
    }

    tr.expandedRowBold {
      font-weight: bold;
    }
  }

  .DnsZoneArchivedDropdown {
    // padding-bottom: 30px;

    .dnsRecords {
      display: grid;
      grid-template-columns: 2fr 1fr 3fr;

      &:hover {
        background-color: $blue-light-color;
      }
    }

    .createButton {
      margin-top: 30px;
    }
  }

  .zoneFile {
    width: 100%;
    height: 400px;
    overflow: auto;
    font-family: monospace;
    padding: 8px;
  }

  .dnsZonesTable {
    .expandedRow {
      background-color: #e6f7ff;
    }
  }

  .dnsSecSwitch {
    background-color: #70C040;
  }

  .dnsRecordsTable {
    margin-top: 3px;

    th {
      background-color: #acdef6;
      font-weight: bold;
    }
    td {
      background-color: #e6f7ff;
    }
  }

  .selectedZone {
    background-color: #e6f7ff;
  }

  .dnsChecks {

  }
}
