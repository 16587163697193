@import '../../../variables.scss';

.nestedTable {
	margin-right: 50px;
	margin-bottom: 50px;
}

td.ant-table-column-sort {
  border-left: solid 1px lightblue;
  border-right: solid 1px lightblue !important;
}
th.ant-table-column-sort {
  border-left: solid 1px lightblue;
  border-right: solid 1px lightblue !important;
}

.invoiceDetailTable {
  .prepared {
    background-color: aliceblue;
  }
  .paid {
    background-color: $green-white-color;
  }
  th.right {
    text-align: center;
  }

  .bg-yellow {
    background-color: yellow;
  }
  .bg-orange {
    background-color: orange;
  }
  .bg-orange {
    background-color: red;
  }
  .bg-green {
    background-color: #c5ffc5;;
  }

  .ant-table-content {
    overflow: visible!important;
  }
}

div.InvoicePage {
  .ant-card-body {
    overflow: visible!important;
    min-width: 1250px!important;
  }
  min-width: 1250px !important;
}
