@import '../../../variables.scss';


.subnetsTable {

  .expandedRow {
    background-color: #e6f7ff;
  }

  tr.private {
    td.marked{
      // background-color: #fce7d2!important;
    }
  }
  tr.public {
    td.marked {
      // background-color: #cffcd3 !important;
    }
  }
  tr.external {
    td.marked {
      // background-color: #cce7f6 !important;
    }
  }
}

.tagPublic {
  border-radius: 5px;
  background-color: #cffcd3;
  font-weight: bold;
  a {
    color: #252951!important;
    text-decoration: underline;
  }
}
.tagExternal {
  border-radius: 5px;
  background-color: #cce7f6;
  font-weight: bold;
  a {
    color: #1890ff!important;
    text-decoration: underline;
  }
}
.tagInternal {
  border-radius: 5px;
  background-color: #fce7d2;
  font-weight: bold;
  a {
    color: #252951!important;
    text-decoration: underline;
  }
}

.IpsPage {
  .ipsTable {
    td {
      padding: 2px!important;
    }
  }
}

.NewSubnetForm {
  .ant-input-number-handler-wrap {
    display: none;
  }
}
