.RolesPage {

  .tag-role {
    font-size: 1em;
    cursor: pointer;
  }

  .tr {
    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    padding: 0 !important;
    margin: 0 !important;
  }

  .tr-selected {
    .td-blue {
      background-color: #e6fffb;
      color: white;
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    padding: 0 !important;
    margin: 0 !important;
  }

  .roles-table {
    min-height: 600px;
    max-height: 1200px;
    height: 90%;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .tdOver {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-body {
      height: 600px;
    }
  }

  .endpoint-table {
    min-height: 600px;
    max-height: 1200px;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .ant-table-body {
      height: 600px;
    }

    .ant-table-wrapper {
      height: 600px;
      overflow: hidden;
    }
  }

  .all-endpoint-table {
    min-height: 600px;
    max-height: 1200px;
    height: 90%;

    .ant-table-cell {
      padding: 5px 1px 5px 1px !important;
      margin: 0 !important;
    }

    .tdOver {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-body {
      height: 600px;
    }
  }
}