@import 'variables.scss';

.BillingPricelistPage {
  .ant-table-expanded-row-level-1 {
    & > td {
      background: rgba($green-color, 0.07);
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: rgba($green-color, 0.04);
    }
    &:hover {
      & > td {
        background: rgba($green-color, 0.1);
      }
    }
  }
}
.ProductPriceDetailsHeader span.ant-tag {
  background: none!important;
  border: none!important;
}

.BillingPricelistTable {
  tr.highlight {
    background: rgba($green-color, 0.1);
    font-weight: bold;
  }
}