@import '../../variables.scss';

.CertificatesTable {
  .old {
    background: $alert-light-color;
  }
  .label-bold {
    font-weight: bold;
    margin-bottom: 0;
  }
  span {
    font-weight: normal;
  }
}